export const OPTIONS = [
  "Agile",
  "Algorithm",
  "API",
  "Application",
  "Adaptive",
  "Bootstrap",
  "Backend",
  "Browser",
  "Bug",
  "Cache",
  "Code",
  "CSS",
  "Data",
  "Debugging",
  "Deployment",
  "Documentation",
  "Domain",
  "Frameworks",
  "Frontend",
  "Full-stack",
  "Git",
  "GitHub",
  "HTML",
  "HTTP",
  "Information",
  "Java",
  "JavaScript",
  "jQuery",
  "Languages",
  "Libraries",
  "Minification",
  "Mobile-first",
  "MVP",
  "MySQL",
  "Operating",
  "PHP",
  "Plugin",
  "Python",
  "Resolution",
  "Responsive",
  "Ruby",
  "Sitemap",
  "Software",
  "SSL",
  "Text",
  "UI",
  "UX",
  "Version",
  "Web",
  "Wireframe",
];
